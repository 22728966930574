import React from 'react';
import styled from 'styled-components';

const ContMain = styled.div`
    margin-left: 180px !important;
    overflow-x: none;

    @media screen and (max-width: 1024px) {
        margin-left: 0px !important;
    }
`;

const ContenidoMain = ({children}) => {
    return (  
        <ContMain>
            {children}
        </ContMain>
    );
}
 
export default ContenidoMain;