import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import theme from '../theme';

const FooterDiv = styled.div`
    padding-top: 100px !important;
    background: white;
`;

const Background = styled.div`
    background: ${theme.fondo};
    height: 70px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Derechos = styled.p`
    color: white;
    font-size: 20px;
    font-family: "Raleway", sans-seriff;
    max-width: 90%;
    text-align: center;
`;

const Aviso = styled(Link)`
    text-decoration: none;
    color: white;
    transition: color 500ms;

    &:hover {
        color: ${theme.azul};
    }
`;

const Footer = () => {
    const date = new Date();
    const currentYear = date.getFullYear();
    
    return (  
        <FooterDiv>
            <Background>
                <Derechos>Todos los derechos reservados &copy; {currentYear} | <Aviso to="/aviso">Aviso de Privacidad</Aviso></Derechos>
            </Background>
        </FooterDiv>
    );
}
 
export default Footer;