import React, { useEffect } from 'react';
import Caja from '../elementos/Caja';
import { Helmet } from 'react-helmet';
import favicon from '../imagenes/Logo.png';
import AcercaDe from '../elementos/About';
import Footer from '../elementos/Footer';

const About = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (  
        <>
            <Helmet>
                <link rel="shortcut icon" href={ favicon } type="image/x-icon" data-react-helmet="true"/>
            </Helmet>
            <Caja>
                <AcercaDe className="about"/>
                <Footer/>
            </Caja>
        </>
    );
}
 
export default About;