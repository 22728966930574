import styled from 'styled-components';
import theme from '../theme';

const Caja = styled.div`
    height: auto;

    h1 {
        color: ${theme.letra};
        text-align: center;
    }

    @media screen and (max-width: 1024px) {
        padding-top: 10% !important;
    }
`;

export default Caja;