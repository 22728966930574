import React, { useEffect } from 'react';
import Caja from '../elementos/Caja';
import { Helmet } from 'react-helmet';
import favicon from '../imagenes/Logo.png';
import styled from 'styled-components';
import Briefcase from '../elementos/Portfolio';
import Footer from '../elementos/Footer';

const Portfolio = styled.div`
    z-index: 100 !important;
    width: 100%;
`;

const Espacio = styled.div`
    min-height: 100vh;
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Portafolio = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <Portfolio>
            <Helmet>
                <link rel="shortcut icon" href={ favicon } type="image/x-icon" data-react-helmet="true"/>
            </Helmet>
            <Caja>
                <Espacio>
                    <Briefcase/>
                </Espacio>
                <Footer/>
            </Caja>
        </Portfolio>
    );
}
 
export default Portafolio;