import React from 'react';
import styled from 'styled-components';
import Footer from '../elementos/Footer';

const Contenedor = styled.div`
    width: 100%;
    height: auto;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const Terminos = styled.div`
    width: 70%;
`;

const H1 = styled.h1`
    padding-top: 40px !important;
    text-align: center;
    padding-bottom: 30px !important;
    font-size: 40px;
    font-family: "Raleway", sans-seriff;

    @media screen and (max-width: 1024px) {
        padding-top: 80px !important;
    }
`;

const P = styled.p`
    margin-bottom: 20px !important;
    font-size: 18px;
    text-align: justify;
    font-family: "Raleway", sans-seriff;
`;

const Ps = styled.p`
    margin-bottom: 20px !important;
    font-size: 18px;
    text-align: justify;
    padding-left: 30px !important;
    font-family: "Raleway", sans-seriff;
`;

const Ul = styled.ul`
    // list-style: none;
    padding-left: 30px !important;

    li {
        padding-left: 30px !important;
        font-weight: 600;
        font-size: 18px;
        margin-bottom: 20px !important;
        font-family: "Raleway", sans-seriff;
    }
`;

const Aviso = () => {
    return (
        <>
            <Contenedor>
                <Terminos>
                    <H1>Aviso de privacidad</H1>
                    <P>De conformidad con lo establecido en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, <strong>alandevweb</strong> pone a su disposición el siguiente aviso de privacidad.</P>

                    <P><span>alandevweb</span>, es responsable del uso y protección de sus datos personales, en este sentido y atendiendo las obligaciones legales establecidas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, a través de este instrumento a los titulares de los datos, la información que de ellos se recaba y los fines que se le darán a dicha información.</P>

                    <P>Los datos personales que recabamos de usted serán utilizados para las siguientes finalidades, las cuales son necesarias para concretar nuestra relación con usted, así como atender los servicios y/o pedidos que solicite:</P>

                    <Ul>
                        <li>Contactar al cliente</li>
                    </Ul>

                    <P> Brindar un mejor servicio al cliente, además de facilitar el contacto con el negocio en caso de dudas por parte del cliente. Así como realizar propaganda y/o mercadotecnia de nuestros productos o servicios.</P>

                    <P>Para llevar a cabo las finalidades descritas en el presente aviso de privacidad, utilizaremos los siguientes datos personales:</P>
                    <Ul>
                        <li>Nombre</li>
                        <li>Correo electrónico</li>
                    </Ul>

                    <P>Por otra parte, informamos a usted, que sus datos personales no serán compartidos con ninguna autoridad, empresa, organización o persona distinta a nosotros y serán utilizados exclusivamente para los fines señalados.</P>

                    <P>Usted tiene en todo momento el derecho a conocer qué datos personales tenemos de usted, para qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); de igual manera, tiene derecho a que su información se elimine de nuestros registros o bases de datos cuando considere que la misma no esté siendo utilizada adecuadamente (Cancelación); así como también oponerse al uso de datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</P>

                    <P>Para el ejercicio de cualquiera de los derechos ARCO, se deberá presentar la solicitud respectiva a través del siguiente correo electrónico:</P>

                    <Ps><strong>alan.developnett@gmail.com</strong></Ps>

                    <P>Lo anterior también servirá para conocer el procedimiento y requisitos para el ejercicio de los derechos ARCO.</P>

                    <P>La respuesta a la solicitud se dará en el siguiente plazo: Máximo 3 días hábiles, y se comunicará de la siguiente manera:</P>
                    <Ps><strong>A través del correo electrónico que se indique en la solicitud.</strong></Ps>
                </Terminos>
            </Contenedor>
            <Footer/>
        </>
    );
}
 
export default Aviso;