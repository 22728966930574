import styled from 'styled-components';
import WebFont from 'webfontloader';
import theme from '../theme';

WebFont.load({
    google: {
        families: ['Antic Slab', 'Raleway', 'Ubuntu Mono', 'monospace']
    }
});

const ContenedorInicio = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    height: 100vh;
    width: 100%;
`;

const ContenedorPequeno = styled.div`
`;

const Titulo = styled.h1`
    font-size: 80px;
    color: ${theme.letraBlanca} !important;
    font-family: "Ubuntu Mono", "Raleway", sans-serif;
    position: relative;
    z-index: 2 !important;
    padding: 0 30px !important;
    font-style: bold;
    line-height: 80px;

    span {
        color: ${theme.azulClaro};
    }

    .title .changeColor:hover {
        color: ${theme.azulFuerte};
        font-size: 90px;
        line-height: 0px;

        @media screen and (max-width: 800px) {
            font-size: 50px;
        }
    }

    @media screen and (max-width: 800px) {
        font-size: 48px;
        line-height: 48px;
        padding-bottom: 20px !important;
    }
`;

const Parrafo = styled.p`
    font-size: 20px;
    color: ${theme.azulClaro} !important;
    font-family: "Ubuntu Mono", sans-serif;
    width: 100%;
    text-align: center;
    position: relative;
    z-index: 2 !important;
`;

export { ContenedorInicio, ContenedorPequeno, Titulo, Parrafo };