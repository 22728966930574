import React, { useEffect } from 'react';
import Caja from '../elementos/Caja';
import { ContenedorInicio, ContenedorPequeno, Titulo, Parrafo } from '../elementos/ElementosDeInicio';
import { Helmet } from 'react-helmet';
import favicon from '../imagenes/Logo.png';
import OpacityBackground from '../elementos/OpacityBackground';
import About from '../elementos/About';
import Portfolio from '../elementos/Portfolio';
import Footer from '../elementos/Footer';
import Contacto from '../elementos/Contacto';

const Inicio = () => {
    
    useEffect(() => {
        const onMouseMove = function() {
            let contenidoMain = document.querySelector('.ContenidoMain');
    
            contenidoMain.onmousemove = function(event) {
                var colors = ['#00B8CC', '#08D6EC', '#10A4C0', '#158BC8'];
        
                var circle = document.createElement('div');
                circle.classList.add('circle');
                contenidoMain.appendChild(circle);
        
                circle.style.left = (event.clientX - 20) + 'px';
                circle.style.top = (event.clientY - 20) + 'px';
        
                var color = colors[Math.floor(Math.random() * colors.length)];
                circle.style.borderColor = color;
        
                circle.style.transition = 'all 0.5s linear 0s';
                circle.style.left = circle.offsetLeft - 200 + 'px';
                circle.style.top = circle.offsetTop - 30 + 'px';
        
                circle.style.width = '40px';
                circle.style.height = '40px';
        
                circle.style.borderWidth = '5px';
                circle.style.opacity = 0;
        
                setTimeout(() => {
                    circle.remove();
                }, 500);
            }
        };
            
        onMouseMove();
    });

    useEffect(() => {
        let messageArray = ["Experto en aplicaciones web"];
        let textPosition = 0;
        let speed = 80;

        let typewriter = () => {
            document.querySelector('.jobMessage').innerHTML = messageArray[0].substring(0, textPosition) + "<span class='blink'>|</span>";

            if(textPosition++ !== messageArray[0].length) {
                setTimeout(typewriter, speed);
            }
        };

        window.addEventListener("load", typewriter);
    }, []);


    useEffect(() => {
        let i, txt, html, node;
        node = document.querySelector('.title');
        txt = node.innerHTML;
        
        html = "";
        for(i = 0; i < txt.length; i++) {
            html += '<span class="changeColor">' + txt.charAt(i) + '</span>';
        }
        
        node.innerHTML = html;
    }, []);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <Helmet>
                <link rel="shortcut icon" href={ favicon } type="image/x-icon" data-react-helmet="true"/>
            </Helmet>

            <Caja>
                <ContenedorInicio className='ContenidoMain'>
                <OpacityBackground/>
                    <ContenedorPequeno className='contenedorPequeno'>
                        <Titulo style={{zIndex: 2}}>Hola, <br/>soy <span className='title'>Alan Jiménez</span>, <br/>desarrollador web</Titulo>
                        <Parrafo className="jobMessage" style={{zIndex: 2}}> </Parrafo>
                    </ContenedorPequeno>
                </ContenedorInicio>

                <About className="about"></About>
                <Portfolio></Portfolio>
                <Contacto></Contacto>
                <Footer></Footer>
            </Caja>
        </>

    );
}
 
export default Inicio;