import React, { useState } from 'react';
import styled from "styled-components";
import theme from "../theme";
import emailjs from '@emailjs/browser';
import { useRef } from "react";
import Alerta from "./Alerta";

const Contact = styled.div`
    background: ${theme.blanco};
    width: 100%;
    height: 600px;
`;

const Titulo = styled.h1`
    padding-top: 60px !important;
    font-size: 35px;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    color: ${theme.azulFuerte} !important;

    @media screen and (max-width: 1024px) {
        padding-top: 60px !important;
    }
`;

const DivForm = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px !important;
`;

const Formulario = styled.form`
    width: 60%;
    height: 400px;

    @media screen and (max-width: 1400px) {
        width: 90%;
    }
`;

const Ul = styled.ul`
    padding: 0 !important;
    margin: 0 !important;
    box-sizing: border-box !important;
    display: block;

    li {
        label {
            font-size: 18px;
            line-height: 30px;
            color: ${theme.azulFuerte};
            font-family: 'Raleway', sans-serif;
            font-weight: 600;
        }
    }
`;

const Li = styled.li`
    width: 49%;
    list-style: none;
    margin-bottom: 10px !important;
    overflow: hidden;
    display: inline-block;
    position: relative;

    &:nth-child(2) {
        margin-left: 2% !important;

        @media screen and (max-width: 800px) {
            margin-left: 0% !important;
        }
    }

    @media screen and (max-width: 800px) {
        width: 100%;
    }
`;

const InputText = styled.input`
    width: 100%;
    height: 40px;
    font-size: 20px;
    border: 0 !important;
    padding: 5px 10px !important;
    background: ${theme.azulMasClaro};
    color: ${theme.azulFuerte};
    outline: none;

    &::placeholder {
        color: ${theme.azulFuerte};
        &:focus {
            opacity: 0.5;
        }
    }
`;

const LiSubject = styled.li`
    list-style: none;
    margin-bottom: 15px !important;
    overflow: hidden;
    display: block;
    position: relative;
`;

const InputSubject = styled.input`
    width: 100%;
    height: 40px;
    font-size: 20px;
    border: 0 !important;
    padding: 5px 10px !important;
    background: ${theme.azulMasClaro};
    color: ${theme.azulFuerte};
    outline: none;

    &::placeholder {
        color: ${theme.azulFuerte};
    }
`;

const LiMessage = styled.li`
    list-style: none;
    margin-bottom: 40px !important;
    overflow: hidden;
    display: block;
    position: relative;
`;

const Message = styled.textarea`
    width: 100%;
    height: 100px;
    font-size: 20px;
    border: 0 !important;
    padding: 10px !important;
    background: ${theme.azulMasClaro};
    color: ${theme.azulFuerte};
    outline: none;

    &::placeholder {
        color: ${theme.azulFuerte};
    }
`;

const LiSubmit = styled.div`
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
`;

const Submit = styled.input`
    width: 40%;
    height: 40px;
    font-size: 20px;
    border: 1px solid ${theme.azulFuerte};
    padding: 5px 10px !important;
    background: ${theme.blanco};
    color: ${theme.azulFuerte};
    outline: none;
    transition: all 500ms;
    border-radius: 5px;

    &:hover {
        background: ${theme.azulFuerte};
        color: white;
        cursor: pointer;
    }
`;

const Contacto = () => {
    const form = useRef();

    // Creamos las variables de los input
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');

    // Creamos una función para cuando hacemos un cambio en el input del form
    const handleChange = (e) => {
        if(e.target.name === 'name') {
            setName(e.target.value);
        } else if (e.target.name === 'email') {
            setEmail(e.target.value);
        } else if (e.target.name === 'subject') {
            setSubject(e.target.value);
        } else if (e.target.name === 'message') {
            setMessage(e.target.value);
        }
    };

    // Creamos un nuevo estado para las alertas
    const [estadoAlerta, cambiarEstadoAlerta] = useState(false);

    // Creamos otro estado para el mensaje de alerta
    const [alerta, cambiarAlerta] = useState({});

    // Function that is used when we click on send
    const sendEmail = (e) => {
        e.preventDefault();

        // Comprobamos que no haya campos vacíos
        if (name === '' || email === '' || subject === '' || message === '') {
            cambiarEstadoAlerta(true);
            cambiarAlerta({
                tipo: 'error',
                mensaje: 'Por favor rellena todos los campos'
            });
            return;
        }

        // Comprobamos del lado del cliente que el correo sea válido
        const expresionRegular = /[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/;
        
        // Comprobamos que el campo correo sea un correo
        if( !expresionRegular.test(email) ) {
            cambiarEstadoAlerta(true);
            cambiarAlerta({
                tipo: 'error',
                mensaje: 'Por favor ingresa un correo válido'
            });
            return;
        }

        try {
            form.current.reset();
            setName('');
            setEmail('');
            setSubject('');
            setMessage('');

            emailjs.sendForm('service_n0fbqul', 'template_didf6fn', form.current, 'Jo-AYhlyG3QFNrSZR')
            .then((result) => {
                console.log(result.text);
                cambiarEstadoAlerta(true);
                cambiarAlerta({
                    tipo: 'exito',
                    mensaje: 'Tu mensaje ha sido enviado!'
                });
            }, (error) => {
                cambiarAlerta(true);
                cambiarAlerta({
                    tipo: 'error',
                    mensaje: 'Algo salió mal, no se envió tu mensaje'
                });
                console.log(error);
            });
            form.current.reset();
        } catch(error) {
            cambiarAlerta(true);
            cambiarAlerta({
                tipo: 'error',
                mensaje: 'Algo salió mal, no se envió tu mensaje'
            });
            console.log(error);
        }
    };

    return (  
        <Contact>
            <Titulo>Contáctame</Titulo>
            <DivForm>
                <Formulario ref={ form } onSubmit={ sendEmail }>
                    <Ul>
                        <Li>
                            <label htmlFor="nombre">Nombre:</label>
                            <InputText 
                                type='text' 
                                id="nombre" 
                                name="name"
                                value={ name }
                                onChange={ handleChange }
                            />
                        </Li>
                        <Li>
                            <label htmlFor="email">Correo:</label>
                            <InputText 
                                type='email' 
                                id="email" 
                                name="email"
                                value={email}
                                onChange={ handleChange }
                            />
                        </Li>
                        <LiSubject>
                            <label htmlFor="subject">Asunto:</label>
                            <InputSubject 
                                type='text' 
                                id="subject" 
                                name="subject"
                                value={ subject }
                                onChange={ handleChange }
                            />
                        </LiSubject>
                        <LiMessage>
                            <label htmlFor="message">Mensaje:</label>
                            <Message 
                                id="Mensaje" 
                                name="message"
                                value={ message }
                                onChange={ handleChange }
                            ></Message>
                        </LiMessage>
                        <LiSubmit>
                            <Submit type='submit'/>
                        </LiSubmit>
                    </Ul>
                </Formulario>

                <Alerta 
                    tipo = { alerta.tipo }
                    mensaje = { alerta.mensaje }
                    estadoAlerta = { estadoAlerta }
                    cambiarEstadoAlerta = { cambiarEstadoAlerta }
                />
            </DivForm>
        </Contact>
    );
}
 
export default Contacto;