import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { Contenedor } from './elementos/Contenedor';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Contenedor className='container-main'>
    <App />
  </Contenedor>
);