import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import theme from '../theme';
import { ReactComponent as LogoWebAzul } from '../imagenes/Logo_AJ_azul.svg';
import { ReactComponent as IconoGithub } from '../imagenes/github.svg';
// import { ReactComponent as IconoInstagram } from '../imagenes/instagram.svg';
import { ReactComponent as IconoLinkedin } from '../imagenes/linkedin.svg';
import { ReactComponent as SVGMenu } from '../imagenes/menu-responsive-three-lines.svg';
import Boton from './Boton';
import { Link } from 'react-router-dom';

const NavbarLateral = styled.div`
    background: ${theme.fondoOscuro};
    width: 180px;
    position: fixed !important;
    display: flex;
    height: 100%;
    z-index: 100 !important;

    .svgMenu {
        display: none;
    }

    @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        width: 100%;
        top: 0;
        left: 0;
        height: 10%;

        .bodyLateral {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;

            .divLogo {
                max-heigth: 50px !important;
                padding-top: 5px !important;

                .logoA {
                    width: 60px !important;
                    height: 60px !important;

                    .logo {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }

        .employee {
            display: none;
        }

        .redes {
            display: none;
        }

        .navbar {
            display: none;
        }

        .svgMenu {
            display: block;
        }

    }
`;

const Body = styled.div`
    @media screen and (max-width: 1024px) {
        .active svg {
            fill: ${theme.azul} !important;
        }
    }
`;

const DivLogo = styled.div`
    width: 100%;    
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    // height: 200px;
    padding-top: 50px !important;

    svg {
        height: 100px;
        width: 100px;
        
        &:hover {
            cursor: pointer;
        }
    }

    @media screen and (max-width: 1024px) {
        max-heigth: 50px !important;
        width: 10%;
        padding-top: 0 !important;

        &:nth-child(2) {
            margin-left: 20px !important;

            svg {
                height: 35px;
                width: 35px;
                fill: ${theme.letraClara};
                transition: all 800ms;
    
                &:hover {
                    cursor: pointer;
                    fill: ${theme.azul};
                }
            }
        }
    }
`;

const Nav = styled.ul`
    width: 100%;
    height: auto;
    list-style: none;
    padding-top: 50px !important;

    li {
        height: 60px;
        border-top: 1px solid #282828;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #6D6D6D;
        font-size: 18px;
        font-family: "Open Sans", sans-serif;
        transition: color 500ms;

        &:hover {
            color: #28E885;
            cursor: pointer;
        }

        &:last-child {
            border-bottom: 1px solid #282828;
        }
    }

    .active {
        color: ${theme.verde};
        font-size: 22px;
    }
`;

const NavResponsive = styled.ul`
    display: none;

    @media screen and (max-width: 1024px) {
        display: block;
        width: 100%;
        height: auto;
        list-style: none;

        li {
            border-top: 1px solid #282828;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #6D6D6D;
            font-size: 10px;
            font-family: "Open Sans", sans-serif;
            transition: color 500ms;

            &:hover {
                color: #28E885;
                cursor: pointer;
            }

            &:last-child {
                border-bottom: 1px solid #282828;
            }
        }

        .active {
            color: ${theme.verde};
            font-size: 22px;
        }
    }
`;

const Redes = styled.ul`
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 40px !important;
    list-style: none;

    a {
        margin: 0 5px !important;

        svg {
            fill: #6D6D6D !important;
            transition: fill 500ms;
            width: 20px;
            height: auto;

            &:hover {
                fill: #28E885 !important;
            }
        }
    }
`;

const RedesResponsive = styled.ul`
    display: none;

    @media screen and (max-width: 1024px) {
        margin-top: 10px !important;
        margin-bottom: 10px !important;
        display: flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        height: 40px;
        width: 100%;

        a {
            margin: 0 5px !important;

            svg {
                fill: #6D6D6D !important;
                transition: fill 500ms;
                width: 25px;
                height: auto;
    
                &:hover {
                    fill: #28E885 !important;
                }
            }
        }
    }
`;

const Empleo = styled.span`
    height: 30px;
    font-size: 16px;
    color: ${theme.letra};
    font-weight: 600;
    padding-bottom: 60px !important;
    transition: color 500ms;

    &:hover {
        color: ${theme.azul};
    }
`;

const ContainerResponsiv = styled.div`
    display: none;
    
    @media screen and (max-width: 1024px) {
        display: block;
        width: 100% !important;
        height: auto;
        background: black;
        position: fixed;
        top: 10%;
        left: -100%;
        z-index: 999;
        transition: left 500ms;
    }
`;

const goUp = () => {
    let goUpDesktop = document.querySelectorAll('.go-up-desktop');

    goUpDesktop.forEach((element) => {
        element.addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    });

    let goUpMobile = document.querySelectorAll('.go-up-mobile');
    goUpMobile.forEach((element) => {
        element.addEventListener('click', () => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        });
    })
};

const NavLateral = (to, ...props) => {
    // Create states
    const [estado, cambiarEstado] = useState(false);
    const [isActive, setIsActive] = useState(false);

    // Creamos funciones
    const show = () => {
        cambiarEstado(!estado);
        setIsActive(!isActive);
    };

    const selectContainer = () => {
        let container = document.querySelector('.container');

        if(estado === true) {
            container.style.left = 0;
        } else {
            container.style.left = '-100%';
        }
    }

    //  Usamos useEffect
    useEffect(() => {
        goUp();
        selectContainer();
    }, [estado]);

    return (
        <>
        <NavbarLateral>
            <Body className='bodyLateral'>
                <DivLogo className='divLogo'>
                    <Link to="/" className='logoA'>
                        <LogoWebAzul className='logo'/>
                    </Link>
                    <Empleo className='employee'>Web developer</Empleo>
                </DivLogo>
                <DivLogo className={isActive === true ? "svgMenu active" : "svgMenu"} onClick={() => show()}>
                    <SVGMenu className="SVG"/>
                </DivLogo>
                <Nav className='navbar'>
                    <Boton to="/" className='go-up-desktop'>
                        Inicio
                    </Boton>
                    <Boton to="/about" className='go-up-desktop'>Acerca de</Boton>
                    <Boton to="/portfolio" className='go-up-desktop'>Portafolio</Boton>
                    <Boton to="/contact" className='go-up-desktop'>Contacto</Boton>
                </Nav>
                <Redes className='redes'>
                    <li>
                        <a href="https://www.linkedin.com/in/alan-jim%C3%A9nez-a46077207/" target='_blank' rel="noopener noreferrer">
                            <IconoLinkedin/>
                        </a>
                    </li>
                    <li>
                        <a href="https://github.com/AlanD24" target='_blank' rel="noopener noreferrer">
                            <IconoGithub/>
                        </a>
                    </li>
                    {/* <li>
                        <a href="https://www.instagram.com/alandevweb/" target='_blank' rel="noopener noreferrer">
                            <IconoInstagram/>
                        </a>
                    </li> */}
                </Redes>
            </Body>
        </NavbarLateral>

        <ContainerResponsiv className='container'>
            <NavResponsive className='navResponsive'>
                <Boton to="/" className='go-up-mobile'>Inicio</Boton>
                <Boton to="/about" className='go-up-mobile'>Acerca de</Boton>
                <Boton to="/portfolio" className='go-up-mobile'>Portafolio</Boton>
                <Boton to="/contact" className='go-up-mobile'>Contacto</Boton>
            </NavResponsive>
            <RedesResponsive>
                <li>
                    <a href="https://www.linkedin.com/in/alan-jim%C3%A9nez-a46077207/" target='_blank' rel="noopener noreferrer">
                        <IconoLinkedin/>
                    </a>
                </li>
                <li>
                    <a href="https://github.com/AlanD24" target='_blank' rel="noopener noreferrer">
                        <IconoGithub/>
                    </a>
                </li>
                {/* <li>
                    <a href="https://www.instagram.com/alandevweb/" target='_blank' rel="noopener noreferrer">
                        <IconoInstagram/>
                    </a>
                </li> */}
            </RedesResponsive>
        </ContainerResponsiv>
        </>
    );
}
 
export default NavLateral;