import React from 'react';
import NavLateral from './elementos/NavLateral';
import ContenidoMain from './componentes/ContenidoMain';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Inicio from './componentes/Inicio';
import Portafolio from './componentes/Portafolio';
import About from './componentes/About';
import Contacto from './componentes/Contacto';
import Aviso from './componentes/Aviso';

function App() {
  return (
    <>
      <BrowserRouter>
        <NavLateral style={{zIndex: 100}}/>
        <ContenidoMain>
            <Routes>
              <Route path='/' element={ <Inicio/>}/>
              <Route path='/portfolio' element={ <Portafolio/> }/>
              <Route path="/about" element={ <About/> }/>
              <Route path="/contact" element={ <Contacto/> }/>
              <Route path="/aviso" element={ <Aviso/> }/>
            </Routes>
        </ContenidoMain>
      </BrowserRouter>
    </>
  );
}

export default App;
