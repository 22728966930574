import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import theme from '../theme';

const slideDown = keyframes`
    0% {
        transform: translateY(-1.25rem); /* 20px */
        opacity: 0;
    }
 
    10% {
        transform: translateY(1.25rem);
        opacity: 1;
    }
    
    90% {
        transform: translateY(1.25rem);
        opacity: 1;
    }
 
    100% {
        transform: translateY(1.25rem);
        opacity: 0;
    }
`;
 
const ContenedorAlerta = styled.div`
    z-index: 200;
    width: calc(100% - 200px);
    position: fixed;
    margin-left: 180px !important;
    left: 0;
    top: 1.25rem; /* 20px */
    display: flex;
    justify-content: center;
    align-items: center;
    animation: ${slideDown} 4s ease forwards;

    @media screen and (max-width: 1024px) {
        margin-left: 0px !important;
        top: 3.5rem;
        width: 100%;
    }
 
    p {
        background-color: ${(props) => {
            if(props.tipo === 'error'){
                return theme.rojo;
            } else if (props.tipo === 'exito') {
                return theme.azulAlarma;
            } else {
                return '#000';
            }
        }};
        color: #fff;
        padding: 1.25rem 2.5rem !important; /* 20px 40px */
        border-radius: 0.31rem; /* 5px */
        box-shadow: 0px 0px 15px rgba(0,0,0,.1);
        text-align: center;
        font-size: 20px;
    }
`;

function getScrollBarWidth () { 
    var inner = document.createElement('p'); 
    inner.style.width = "100%"; 
    inner.style.height = "200px"; 
  
    var outer = document.createElement('div'); 
    outer.style.position = "absolute"; 
    outer.style.top = "0px"; 
    outer.style.left = "0px"; 
    outer.style.visibility = "hidden"; 
    outer.style.width = "200px"; 
    outer.style.height = "150px"; 
    outer.style.overflow = "hidden"; 
    outer.appendChild (inner); 
  
    document.body.appendChild (outer); 
    var w1 = inner.offsetWidth; 
    outer.style.overflow = 'scroll'; 
    var w2 = inner.offsetWidth; 
    if (w1 === w2) w2 = outer.clientWidth; 
  
    document.body.removeChild (outer); 

    let result = w1 -w2
  
    return result; 
};

const Alerta = ({tipo, mensaje, estadoAlerta, cambiarEstadoAlerta}) => {

    // Creamos hook de useState para conseguir el ancho del scrollbar
    const [ scrollBarWidth, setScrollBarWidth ] = useState(0);

    setTimeout(() => {
        let width = getScrollBarWidth();
        setScrollBarWidth(width);
        if(scrollBarWidth) {
            return '';
        }
    }, 100);
    
    // Creamos un hook de useEffect
    useEffect(() => {
        let tiempo;

        if(estadoAlerta === true) {
            tiempo = setTimeout(() => {
                cambiarEstadoAlerta(false);
            }, 4000);
        }

        // Pasamos una función de limpieza. Esta función se ejecuta si el componente se desmonta.
        // Lo que hacemos es limpiar el tiempo para que no intente cambiar el estado si el componente no está en pantalla.
        // Es importante para poder cambiar componentes en pantalla sin errores.
        return(() => clearTimeout(tiempo));
    }, [estadoAlerta, cambiarEstadoAlerta]);

    return (
        <>
            {/* Si estado alerta es verdadero, se muestra, si no no */}
            {estadoAlerta && 
                <ContenedorAlerta tipo={tipo}>
                    <p> { mensaje } </p>
                </ContenedorAlerta>
            }
        </>
    );
}
 
export default Alerta;