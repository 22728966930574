import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

const Boton = styled(NavLink)`
    height: 60px;
    border-top: 1px solid #282828;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #6D6D6D;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    transition: color 500ms;
    text-decoration: none;

    &:hover {
        color: #28E885;
        cursor: pointer;
    }

    &:last-child {
        border-bottom: 1px solid #282828;
    }

    @media screen and (max-width: 800px) {
        height: 40px;
    }
`;

export default Boton;