import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import {ReactComponent as ArrorRight} from '../imagenes/arrow-right.svg';
import ImgFEA from '../imagenes/Imagen_FEA.png';
// import ImgHeroGym from '../imagenes/HeroGymNew.png';
import ImgAppGastos from '../imagenes/AppGastos.png';

const Portafolio = styled.div`
    width: 100%;
    height: auto;
    background: ${theme.blanco};
    padding-bottom: 60px !important;
`;

const Titulo = styled.h1`
    padding-top: 60px !important;
    font-size: 35px;
    text-align: center;
    font-family: 'Raleway', sans-serif;
    color: ${theme.azulFuerte} !important;

    @media screen and (max-width: 1024px) {
        padding-top: 60px !important;
    }
`;

const Descripcion = styled.h2`
    font-weight: 300;
    font-family: 'Raleway', sans-serif;
    font-size: 20px;
    width: 40%;
    margin: 0 auto !important;
    text-align: center;
    margin-top: 30px !important;

    @media screen and (max-width: 1024px) {
        width: 90%;
    }
`;

const Proyectos = styled.div`
    width: 90%;
    margin: 0 auto !important;
    height: auto;
    margin-top: 50px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media screen and (max-width: 1024px) {
        width: 100%;
    }
`;

const Proyecto = styled.div`
    width: 32%;
    height: 250px !important;
    padding: 7px !important;
    border-bottom: 1px solid white;
    display: flex;

    @media screen and (max-width: 1500px) {
        width: 45%;
        justify-content: center;
    }

    @media screen and (max-width: 800px) {
        width: 90%;
        justify-content: center;
    }
`;

const Figura = styled.figure`
    border: 0 !important;
    position: relative !important;
    overflow: hidden;
    border-radius: 10px !important;
    margin: 0 auto !important;
`;

const Imagen = styled.img`
    display: block;
    height: 100%;
    width: 100%;
    transform: scale(1);
    vertical-align: baseline;
    margin-left: auto !important;
    margin-right: auto !important;

    @media screen and (max-width: 1024px) {
        width: auto;
    }
`;

const FigCaption = styled.figcaption`
    padding: 0 30px !important;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    opacity: 0;
    transition: all 500ms ease-in-out;
    background: rgba(0, 0, 0, 0.5);
    

    & > * {
        transform: translateY(20px);
        transition: transform 500ms ease-in-out;
    }

    &:hover {
        opacity: 1;
        z-index: 10 !important;
        
        & > * {
            transform: translateY(0);
        }
    }

    .overlay--blur {
        backdrop-filter: blur(5px) !important;
    }
`;

const H2 = styled.h2`
    padding-top: 20px !important;
    text-align: center;
    margin-bottom: 30px !important;
    font-family: "Raleway", sans-serif;
    color: white;
    font-size: 20px;
`;

const DivCentrar = styled.div`
    display: flex;
    justify-content: center;
`;

const Boton = styled.a`
    font-size: 18px;
    padding: 10px 20px !important;
    font-weight: 300;
    font-family: "Raleway", sans-serif;
    color: white;
    background-color: transparent;
    border: 2px solid ${theme.azulFuerte} !important;
    border-radius: 20px !important;
    width: auto;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    transition: background 500ms;
    text-decoration: none;

    &:hover {
        background: ${theme.azulFuerte};
    }
`;

const SpanIcono = styled.span`
    height: 16px;
    width: 16px;
    color: white;
    margin-left: 5px !important;

    svg {
        fill: white;
        width: 100%;
        height: 100%;
    }
`;

const Portfolio = () => {
    return (  
        <Portafolio>
            <Titulo>Trabajo reciente</Titulo>
            <Descripcion>Acá iré colocando mis proyectos recientes. Debido a que soy nuevo en esto, no cuento con muchos.</Descripcion>
            <Proyectos>
                <Proyecto>
                    <Figura className='fig-image'>
                        <Imagen alt="Fórmula entre amigos img"  className='project-thumb' src={ImgFEA} />
                        <FigCaption className='overlay overlay--blur'>
                            <H2>Sitio de Fórmula 1 donde se reúne mercancía de escuderías, podcast y memes.</H2>
                            <DivCentrar>
                                <Boton href='https://formulaentreamigos.com/' target='_blank'>
                                    <span>Ver sitio web</span>
                                    <SpanIcono>
                                        <ArrorRight/>
                                    </SpanIcono>
                                </Boton>
                            </DivCentrar>
                        </FigCaption>
                    </Figura>
                </Proyecto>
                <Proyecto>
                <Figura className='fig-image'>
                        <Imagen alt="App gastos" className='project-thumb' src={ImgAppGastos} />
                        <FigCaption className='overlay overlay--blur'>
                            <H2>Página creada con React para llevar la contabilidad de tus gastos</H2>
                            <DivCentrar>
                                <Boton href='https://app-lista-gastos-5ec00.web.app/' target='_blank'>
                                    <span>Ver sitio web</span>
                                    <SpanIcono>
                                        <ArrorRight/>
                                    </SpanIcono>
                                </Boton>
                            </DivCentrar>
                        </FigCaption>
                    </Figura>
                </Proyecto>
                
            </Proyectos>
        </Portafolio>
    );
}
 
export default Portfolio;