import React, { useEffect } from 'react';
import Caja from '../elementos/Caja';
import { Helmet } from 'react-helmet';
import favicon from '../imagenes/Logo.png';
import Contact from '../elementos/Contacto';
import Footer from '../elementos/Footer';
import styled from 'styled-components';

const Espacio = styled.div`
    min-height: 100vh;
    background: white;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Contacto = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (  
        <>
            <Helmet>
                <link rel="shortcut icon" href={ favicon } type="image/x-icon" data-react-helmet="true"/>
            </Helmet>
            <Caja>
                <Espacio>
                    <Contact/>
                </Espacio>
                <Footer/>
            </Caja>
        </>
    );
}
 
export default Contacto;