import React from 'react';
import styled from 'styled-components';
import theme from '../theme';
import IconoFrontend from '../imagenes/frontend.webp';
import IconoBackend from '../imagenes/backend.webp';
import IconoWordpress from '../imagenes/wordpress.webp';

const AcercaDe = styled.div`
    width: 100%;
    height: auto;
    background: ${theme.blanco};
    margin-left: 180x;
    z-index: 10 !important;

    h2 {
        color: white;
        text-align: center;
    }

    @media screen and (max-width: 1024px) {
        margin-left: 0px !important;
    }
`;

const DivTitulo = styled.div`
    height: 600px;
    background: ${theme.azul};
`;

const DivCentrar = styled.div`
    height: 300px;
    width: 800px;
    margin: 0 auto !important;
    padding-top: 100px !important;

    @media screen and (max-width: 800px) {
        width: 400px;
    }
`;

const Titulo = styled.h2`
    color: white;
    text-align: center;
    width: 100%;
    font-size: 30px;
    padding-top: 40px !important;
    font-family: "Raleway", sans-serif;

    @media screen and (max-width: 800px) {
        padding-top: 0px !important;
    }
`;

const Subtitle = styled.h3`
    color: white;
    text-align: center;
    width: 100%;
    font-size: 22px;
    padding-top: 8px !important;
    font-family: "Raleway", sans-serif;

    @media screen and (max-width: 800px) {
        padding-top: 5px !important;
    }
`;

const Parrafo = styled.p`
    color: white;
    text-align: center;
    font-size: 20px;
    font-family: "Raleway", sans-serif;

    @media screen and (max-width: 1024px) {
        padding: 0 20px !important;
        padding-top: 30px !important;
    }

    @media screen and (max-width: 850px) {
        padding: 0 20px !important;
        padding-top: 30px !important;
    }

    @media screen and (max-width: 800px) {
        padding: 0 !important;
        padding-top: 30px !important;
    }

    padding: 0 !important;
    padding-top: 30px !important;
`;

const DivConocimientos = styled.div`
    background: ${theme.blanco};
    width: 75%;
    height: auto;
    margin: 0 auto !important;
    margin-top: -100px !important;
    border-radius: 10px;
    // border: 1px solid black;
    box-shadow: 0 5px 5px 0 rgba(232, 241, 255, 50%), 0 0 0 1px ${theme.gris};

    @media screen and (max-width: 1024px) {
        margin-top: -40px !important;
        width: 90%;
    }
`;

const Columnas = styled.div`
    display: flex;
    justify-content: center;
    align-items: baseline;
    height: 100%;

    @media screen and (max-width: 800px) {
        flex-wrap: wrap;
    }
`;

const Columna = styled.div`
    border-radius: 10px;
    border: 1px solid white;
    display: block;
    // flex-basis: 0;
    // flex-grow: 1;
    // flex-shrink: 1;
    height: 100%;
    padding: 70px 45px !important;
    border-right: 1px solid ${theme.azulMasClaro};

    @media screen and (max-width: 800px) {
        width: 100%;

        &:nth-child(2) {
            border-top: 1px solid ${theme.azulMasClaro};
            border-radius: 0px;
        }

        &:last-child {
            border-top: 1px solid ${theme.azulMasClaro};
            border-radius: 0px;
        }
    }

    &:last-child {
        border-right: 0px;
    }

    figure {
        margin: 0 auto;
        text-align: center;
        img {
            height: 50px;
            width: auto;
            
            @media screen and (max-width: 800px) {
                height: 80px;
            }
        }
    }

    h3 {
        text-align: center;
        margin: 20px 0 !important;
        font-family: "Raleway", sans-serif;

        @media screen and (max-width: 800px) {
            font-size: 25px;
        }
    }

    p {
        text-align: center;
        line-height: 30px;
        margin-bottom: 40px !important;
        font-size: 18px;
        font-family: "Raleway", sans-serif;
    }

    .has-text-primary {
        color: ${theme.azulFuerte};
        font-weight: 600;
        margin-bottom: 5px !important;

        @media screen and (max-width: 800px) {
            color: {theme.azul};
        }
    }

    ul {
        list-style: none;
        
        li {
            text-align: center;
            padding: 5px 0 !important;
            font-size: 16px;
            font-family: "Raleway", sans-serif;

            @media screen and (max-width: 800px) {
                font-size: 18px;
            }
        }
    }
`;

const Espacio = styled.div`
    width: 100%;
    height: 80px;
`;

const About = () => {
    return (  
        <AcercaDe>
            <DivTitulo>
                <DivCentrar>
                    <Titulo>¡Gusto en conocerte!</Titulo>
                    <Subtitle>Tú pones la idea y yo el código <span role="img" aria-label='emoji-happy'>😄</span></Subtitle>
                    <Parrafo>
                        Soy un ingeniero apasionado por la tecnología que empezó el desarrollo web como un hobby, pero actualmente 
                        quiero profundizar en este maravilloso y complejo mundo de la programación. Me encanta el desarrollo 
                        frontend, ya que me considero una persona creativa y que le gustan los sitios web visualmente llamativos. 
                        Sin embargo, también tengo noción y me gusta aprender del backend para darle vida a las aplicaciones web. 
                        También he aprendido a crear aplicaciones móbiles, tanto para iOS como para android.
                    </Parrafo>                   
                </DivCentrar>
            </DivTitulo>

            <DivConocimientos>
                <Columnas>
                    <Columna>
                        <figure className='image'>
                            <img className='skill-icon' src={IconoFrontend} alt='Frontend'/>
                        </figure>
                        <h3>Desarrollador Frontend</h3>
                        <p>Me agradan los patrones de diseño limpios, las interacciones bien pensadas y un contenido agradable.</p>
                        <p className='has-text-primary'>Tecnologías utilizadas:</p>
                        <p>HTML, CSS, Javascript, React, Angular, Ionic.</p>
                        {/* <p className='has-text-primary'>Desarrollo:</p>
                        <ul>
                            <li>Sitios web informativos</li>
                            <li>Landing pages</li>
                            <li>Sitios web personales</li>
                        </ul> */}
                    </Columna>
                    <Columna>
                        <figure className='image'>
                            <img className='skill-icon' src={IconoWordpress} alt='Wordpress'/>
                        </figure>
                        <h3>Integración de CMS</h3>
                        <p>Una herramienta bastante útil cuando el cliente quiere hacer cambios a la página él mismo.</p>
                        <p className='has-text-primary'>Para personalización:</p>
                        <p>HTML, CSS, Javascript, PHP.</p>
                        {/* <p className='has-text-primary'>Diseño:</p>
                        <ul>
                            <li>Blogs</li>
                            <li>Sitios web informativos</li>
                            <li>Tiendas</li>
                        </ul> */}
                    </Columna>
                    <Columna>
                        <figure className='image'>
                            <img className='skill-icon' src={IconoBackend} alt='Backend'/>
                        </figure>
                        <h3>Desarrollador Backend</h3>
                        <p>Puedo crear funcionalidades complejas desde el backend para así crear aplicaciones web o móviles que requieran alto grado de personalización.</p>
                        <p className='has-text-primary'>Tecnologías utilizadas:</p>
                        <p>NodeJS, MongoDB, PHP, MySQL</p>
                        {/* <p className='has-text-primary'>Desarrollo:</p>
                        <ul>
                            <li>Blogs</li>
                            <li>Sitios de reservas por cita</li>
                            <li>Aplicaciones web sencillas</li>
                        </ul> */}
                    </Columna>
                </Columnas>
            </DivConocimientos>
            <Espacio/>
        </AcercaDe>
    );
}
 
export default About;