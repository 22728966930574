import styled from 'styled-components';
import background from '../imagenes/background.webp';
import mobile from '../imagenes/laptopDark.jpg';

const Contenedor = styled.div`
    background-image: url(${mobile});
    background-size: auto !important;
    background-position: inherit !important;
    background-repeat: no-repeat !important;
    
    @media screen and (min-width: 800px) {
        background-size: cover !important;
        background-image: url(${background});
        background-attachment: fixed !important;
        background-position: center center !important;
    }
`;

export { Contenedor };