export const theme = {
    fondo: '#292929',
    fondoOscuro: '#1B1B1B',
    blanco: '#fff',
    gris: '#F3F3F3',
    grisFuerte: '#2b2b2b',
    letra: '#6D6D6D',
    letraClara: "#A29D9D",
    letraBlanca: '#fff',
    verde: '#28E885',
    verdeFuerte: '#0C763D',
    azul: '#39B0E5',
    azulClaro: '#8FBBF9',
    azulMasClaro: '#E8F1FF',
    azulFuerte: '#0A61DC',
    azulAlarma: '#133A71',
    amarillo: '#F9E30B',
    verdeAzul: '#0BF9C9',
    rojo: "#E34747"
}

export default theme;