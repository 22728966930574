import styled from 'styled-components';
import React from 'react';

const FondoOscuro = styled.div`
    background-color: #000;
    opacity: 0.4;
    z-index: 2 !important;
    height: 100vh;
    width: 100%;
    position: absolute;
    margin-right: 180px !important;

    @media screen and (max-width: 1024px) {
        margin-right: 0px !important;
    }
`;

const OpacityBackground = () => {
    return (
        <FondoOscuro/>
    );
}

export default OpacityBackground;